const GRAPHQL_API_DEV_URL = 'https://graphql-dev.sketch.cloud/api/graphql'
const GRAPHQL_API_STAGING_URL = 'https://graphql-staging.sketch.cloud/api/graphql'
const GRAPHQL_API_PROD_URL = 'https://graphql.sketch.cloud/api/graphql'

const GRAPHQL_PAYMENTS_API_DEV_URL = 'https://payments.dev.sketch.com/payments/graphql'
const GRAPHQL_PAYMENTS_API_STAGING_URL = 'https://payments.staging.sketch.com/payments/graphql'
const GRAPHQL_PAYMENTS_API_PROD_URL = 'https://payments.prod.sketch.com/payments/graphql'

const BASE_URL = 'https://api.prod.sketch.com/1/'
const BASE_URL_NON_PROD = 'https://api.staging.sketch.com/1/'

const BASE_URL_ENV_MAP = {
  dev: BASE_URL_NON_PROD,
  development: BASE_URL_NON_PROD,
  staging: BASE_URL_NON_PROD,
  production: BASE_URL,
}

const GRAPHQL_URL_ENV_MAP = {
  dev: GRAPHQL_API_DEV_URL,
  development: GRAPHQL_API_DEV_URL,
  staging: GRAPHQL_API_STAGING_URL,
  production: GRAPHQL_API_PROD_URL,
}

const GRAPHQL_PAYMENTS_URL_ENV_MAP = {
  dev: GRAPHQL_PAYMENTS_API_DEV_URL,
  development: GRAPHQL_PAYMENTS_API_DEV_URL,
  staging: GRAPHQL_PAYMENTS_API_STAGING_URL,
  production: GRAPHQL_PAYMENTS_API_PROD_URL,
}

export const GRAPHQL_API_URL = GRAPHQL_URL_ENV_MAP[GLOBALS.ENV]
export const GRAPHQL_PAYMENTS_API_URL = GRAPHQL_PAYMENTS_URL_ENV_MAP[GLOBALS.ENV]
export const BASE_API_URL = BASE_URL_ENV_MAP[GLOBALS.ENV]

export const SIGN_IN_ENDPOINT = GRAPHQL_API_URL.replace('/api/graphql', '/oauth/token')

//
// FastSpring common functions
//

//
// Overcome FS limit of $20.000
//
// instead of sending one number with total, sending integer separate from decimals
//
// Solution: https://github.com/sketch-hq/Sketch/issues/14021#issuecomment-309026123
// <input type="hidden" name="tags" data-field="payment_info_total" value="total_integer=29051,total_fraction=34">
//
// The following happens in this function, for total_integer and total_fraction respectively:
//
// a total of 2,01 is 2 and 1
// a total of 2,10 is 2 and 10
// a total of 2,54 is 2 and 54
//
const dataTreatmentBeforeRender = function(data) {
  var treatedData = data
  var fsTotalStr = ''
  var total = data.data.total
  var totalInteger = Math.floor(total)
  var totalFraction = Math.floor((total * 100) % 100)

  fsTotalStr = 'total_integer=' + totalInteger
  fsTotalStr += ',total_fraction=' + totalFraction

  treatedData.data.fs_total_str = fsTotalStr

  return treatedData
}

export default {
  dataTreatmentBeforeRender,
}

import * as consts from './base/consts'
import * as utils from './base/utils'
import FS from './base/fastspring'
import API from './base/api'
import $$ from './base/_appeltaart'

// Add some of these to window, needed by other JS files included after this one
Object.assign(window, {
  $$,
  Sketch: {
    consts,
    utils,
    FS,
    API,
  },
})
